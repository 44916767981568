<template>
  <div class="azati-vwc-our-clients" id="azati-vwc-our-clients">
    <div class="row-wrapper">
      <!-- We are adding keys to force Vue to rerender components instead of just passing bew data into it  -->
      <our-clients-row
        v-if="currentRow === 1"
        @animation-finished="setCurrentRow(2)"
        :elements="[auction, chilewich, citizen, clarivate]"
        key="row-1"
      ></our-clients-row>
      <our-clients-row
        v-if="currentRow === 2"
        @animation-finished="setCurrentRow(3)"
        :elements="[croc, digatex, geolytics, mangrove]"
        key="row-2"
      ></our-clients-row>
      <our-clients-row
        v-if="currentRow === 3"
        @animation-finished="setCurrentRow(4)"
        :elements="[doxa, medpro, roscosmos, careerStarGroup]"
        key="row-3"
      ></our-clients-row>
      <our-clients-row
        v-if="currentRow === 4"
        @animation-finished="setCurrentRow(5)"
        :elements="[teambook, thp, venstarExchange, host]"
        key="row-4"
      ></our-clients-row>
      <our-clients-row
        v-if="currentRow === 5"
        @animation-finished="setCurrentRow(1)"
        :elements="[aptagen, cib, cmcs, es, fliit, marketboomer]"
        key="row-5"
      ></our-clients-row>
    </div>
    <div class="dots-wrapper">
      <div
        :class="['single-dot', currentRow === 1 ? 'active' : '']"
        @click="setCurrentRow(1)"
      ></div>
      <div
        :class="['single-dot', currentRow === 2 ? 'active' : '']"
        @click="setCurrentRow(2)"
      ></div>
      <div
        :class="['single-dot', currentRow === 3 ? 'active' : '']"
        @click="setCurrentRow(3)"
      ></div>
      <div
        :class="['single-dot', currentRow === 4 ? 'active' : '']"
        @click="setCurrentRow(4)"
      ></div>
      <div
        :class="['single-dot', currentRow === 5 ? 'active' : '']"
        @click="setCurrentRow(5)"
      ></div>
    </div>
  </div>
</template>

<script>
import { getClientByName } from "../utils/ourClients";

import OurClientsRow from "./utility/OurClientsRow";

export default {
  name: "OurClients",
  data() {
    return {
      currentRow: 1,
      // Clients
      aptagen: getClientByName("Aptagen"),
      auction: getClientByName("Auction.com"),
      chilewich: getClientByName("Chilewich"),
      cib: getClientByName("CIB"),
      citizen: getClientByName("Citizen"),
      clarivate: getClientByName("Clarivate"),
      cmcs: getClientByName("CMSC"),
      croc: getClientByName("CROC"),
      digatex: getClientByName("Digatex"),
      doxa: getClientByName("DOXA Insurance"),
      es: getClientByName("Empyreal Strings"),
      fliit: getClientByName("Fliit"),
      geolytics: getClientByName("Geolytics"),
      host: getClientByName("HOST"),
      iomsn: getClientByName("IOMSN"),
      mangrove: getClientByName("Mangrove"),
      marketboomer: getClientByName("Marketboomer"),
      medpro: getClientByName("MedPro Group"),
      roscosmos: getClientByName("Roscosmos"),
      careerStarGroup: getClientByName("Career Star Group"),
      teambook: getClientByName("Teambook"),
      thp: getClientByName("TopHotelProjects"),
      venstarExchange: getClientByName("Venstar Exchange"),
    };
  },
  methods: {
    setCurrentRow(value) {
      this.currentRow = value;
    },
  },
  components: {
    "our-clients-row": OurClientsRow,
  },
};
</script>

<style lang="scss">
@import "../assets/scss/_vars.scss";

.azati-vwc-our-clients {
  .row-wrapper {
    @media screen and (min-width: 768px) {
      min-height: 85px;
    }

    @media screen and (max-width: 767.88px) {
      min-height: 222px;
    }

    @media screen and (max-width: 664.88px) {
      min-height: 202px;
    }

    @media screen and (max-width: 561.88px) {
      min-height: 170px;
    }

    @media screen and (max-width: 551.88px) {
      min-height: 168px;
    }

    @media screen and (max-width: 489.88px) {
      min-height: 160px;
    }

    @media screen and (max-width: 449.88px) {
      min-height: 150px;
    }

    @media screen and (max-width: 399.88px) {
      min-height: 130px;
    }

    @media screen and (max-width: 379.88px) {
      min-height: 129px;
    }
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80px;
    flex-wrap: wrap;

    .col {
      width: 100%;
      padding: 10px 10px;
      box-sizing: border-box;

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        filter: grayscale(100%);
        cursor: pointer;
        transition: all 0.5s ease;

        &:hover {
          filter: grayscale(0%);
        }
      }
    }

    &.four-items {
      min-height: 80px;

      .col {
        max-width: 25%;
      }

      @media screen and (max-width: 767.88px) {
        .col {
          max-width: 50%;
        }
      }
    }
    &.six-items {
      .col {
        max-width: 16.66%;
      }

      @media screen and (max-width: 767.88px) {
        .col {
          max-width: 33.33%;
        }
      }
    }
  }
  .dots-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;

    .single-dot {
      width: 10px;
      height: 10px;
      background-color: lightgray;
      border-radius: 50%;
      margin: 5px;
      transition: background-color 0.5s ease;
      cursor: pointer;

      &.active {
        background-color: $color-azati-blue;
      }
    }
  }
}
</style>
