<template>
  <div class="azati-vwc-input-checkbox">
    <input-validation-label
      :isVisible="isDirty && isInvalid"
      :message="errorMessage"
    ></input-validation-label>
    <div class="internal-wrapper">
      <input type="checkbox" :id="id" :name="id" v-model="value" />
      <label :for="id" v-html="labelMessage"></label>
    </div>
  </div>
</template>

<script>
import InputValidationLabel from "./InputValidationLabel";

import { generateUID } from "../../../utils/generateUID";

export default {
  name: "InputCheckbox",
  props: {
    labelMessage: {
      type: String,
      default: "This is default label message",
    },
    isDirty: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "Insert your error message",
    },
  },
  data() {
    return {
      value: false,
      id: generateUID(),
    };
  },
  watch: {
    value: function(value) {
      this.$emit("input", value);
    },
  },
  components: {
    "input-validation-label": InputValidationLabel,
  },
};
</script>

<style lang="scss">
@import url("../../../assets/scss/_vars.scss");

.azati-vwc-input-checkbox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  .internal-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    input {
      margin: 0px;
      width: 20px;
      height: 20px;
      background-color: transparent;
      border-color: red;
      outline-color: transparent;

      -webkit-appearance: none;
      -moz-appearance: none;

      position: relative;

      &:before {
        position: relative;
        display: block;

        content: " ";
        width: 20px;
        height: 20px;
        background-color: #ff9c42;
        border-radius: 6px;
      }

      &:checked:after {
        content: " ";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        background-image: url(https://static.azati.ai/azati-vwc/resources/blog-bottom-form/check-mark-white.svg);
        background-position: center center;
        background-size: 65%;
        background-repeat: no-repeat;
      }

      &:hover {
        cursor: pointer;
      }
    }

    label {
      font-family: "Poppins", "Verdana", sans-serif;
      font-size: 14px;
      color: #6e6e6e;
      margin-left: 7px;

      &:hover {
        cursor: pointer;
      }

      a {
        text-decoration: none;
      }
    }
  }
}
</style>

<style lang="scss">
.azati-vwc-input-checkbox {
  label {
    a {
      text-decoration: none;
      color: #ff9c42;

      transition: color 0.3s ease;
      text-decoration: underline;

      &:hover,
      &:focus,
      &:visited {
        color: #f9823a;
      }
    }
  }
}
</style>
