<template>
  <div class="azati-vwc-blog-bottom-form" id="azati-vwc-blog-bottom-form">
    <input-text
      placeholder="First name*"
      :isDirty="$v.firstName.$dirty"
      :isInvalid="$v.firstName.$invalid"
      :errorMessage="this.firstNameErrorMessage"
      v-model.trim="$v.firstName.$model"
    ></input-text>
    <input-text
      placeholder="Last name*"
      :isDirty="$v.lastName.$dirty"
      :isInvalid="$v.lastName.$invalid"
      :errorMessage="this.lastNameErrorMessage"
      v-model.trim="$v.lastName.$model"
    ></input-text>
    <input-text
      placeholder="Email*"
      :isDirty="$v.email.$dirty"
      :isInvalid="$v.email.$invalid"
      :errorMessage="this.emailErrorMessage"
      v-model.trim="$v.email.$model"
    ></input-text>
    <textarea-component
      placeholder="Type your message here*"
      :isDirty="$v.message.$dirty"
      :isInvalid="$v.message.$invalid"
      :errorMessage="this.messageErrorMessage"
      v-model.trim="$v.message.$model"
    ></textarea-component>

    <input-checkbox
      labelMessage='
        I permit to collect my data according to <a href="/privacy-policy" target="_blank">Privacy Policy</a> 
        and <a href="/terms-of-use" target="_blank">Terms of Use</a>.
      '
      :isDirty="$v.consent.$dirty"
      :isInvalid="$v.consent.$invalid"
      :errorMessage="this.consentErrorMessage"
      v-model.trim="$v.consent.$model"
    ></input-checkbox>

    <div class="button-wrapper">
      <form-alert
        v-visible="showAlert"
        message='Unexpected error! Please, reach us at <a href="mailto:">info@azati.ai</a>'
      ></form-alert>

      <button-component
        @click.native="handleButtonClick"
        class="azati-vwc-button--medium"
      >
        <span>{{ buttonMessage }}</span>
      </button-component>
    </div>
  </div>
</template>

<script>
// While build the CLI ignores main.js, that's why it is critical to invoke and attach plugins directly in component files
import Vue from "vue";
import { Vuelidate } from "vuelidate";

Vue.use(Vuelidate);
Vue.directive("visible", function(el, binding) {
  el.style.visibility = binding.value ? "visible" : "hidden";
});

import { required, minLength, email } from "vuelidate/lib/validators";

import InputText from "../components/utility/forms/InputText";
import Textarea from "../components/utility/forms/Textarea";
import InputCheckbox from "../components/utility/forms/InputCheckbox";
import FormAlert from "../components/utility/forms/FormAlert";
import Button from "../components/utility/forms/Button";

const mustBeTrue = (value) => value === true;

export default {
  name: "BlogBottomForm",
  components: {
    "input-text": InputText,
    "button-component": Button,
    "textarea-component": Textarea,
    "input-checkbox": InputCheckbox,
    "form-alert": FormAlert,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      message: "",
      consent: false,
      processing: false,
      buttonMessage: "Send message",
      showAlert: false,
    };
  },
  methods: {
    handleButtonClick() {
      this.$v.$touch();

      if (this.$v.$anyError === false) {
        this.processing = true;
        this.buttonMessage = "Processing";

        fetch("/FormAPI/blog-bottom-form/", {
          // Fetch docs are here: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Authorization: "fjF*3ifj3nap1!04f82dsj!!#%MqpzZQM",
          },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: JSON.stringify({
            firstname: this.firstName,
            lastname: this.lastName,
            email: this.email,
            message: this.message,
            pageUri: window.location.href,
            pageTitle: document.title,
          }),
        }).then((response) => {
          this.processing = false;
          if (response.status === 200) {
            this.buttonMessage = "Success!";
          } else {
            this.showAlert = true;
            this.buttonMessage = "Error!";
          }
        });
      }
    },
  },
  computed: {
    firstNameErrorMessage: function() {
      if (!this.firstNameIsValid) {
        if (!this.$v.firstName.minLength) {
          return "Minimum length of this field is 3 characters";
        }
        if (!this.$v.firstName.required) {
          return "This field is required";
        }
      }
      return "The field seems to be valid";
    },
    firstNameIsValid: function() {
      return (
        this.$v.firstName.$dirty === true &&
        this.$v.firstName.$invalid === false
      );
    },
    lastNameErrorMessage: function() {
      if (!this.lastNameIsValid) {
        if (!this.$v.lastName.minLength) {
          return "Minimum length of this field is 3 characters";
        }
        if (!this.$v.lastName.required) {
          return "This field is required";
        }
      }
      return "The field seems to be valid";
    },
    lastNameIsValid: function() {
      return (
        this.$v.lastName.$dirty === true && this.$v.lastName.$invalid === false
      );
    },
    emailErrorMessage: function() {
      if (!this.emailIsValid) {
        if (!this.$v.email.required) {
          return "This field is required";
        }
        if (!this.$v.email.email) {
          return "Email seems to be invalid";
        }
      }
      return "The field seems to be valid";
    },
    emailIsValid: function() {
      return this.$v.email.$dirty === true && this.$v.email.$invalid === false;
    },
    messageErrorMessage: function() {
      if (!this.messageIsValid) {
        if (!this.$v.message.minLength) {
          return "Minimum length of this field is 5 characters";
        }
        if (!this.$v.message.required) {
          return "This field is required";
        }
      }
      return "The field seems to be valid";
    },
    messageIsValid: function() {
      return (
        this.$v.message.$dirty === true && this.$v.message.$invalid === false
      );
    },
    consentErrorMessage: function() {
      if (!this.consentIsValid) {
        if (!this.$v.consent.mustBeTrue) {
          return "The consent is required for data processing";
        }
      }
      return "The field seems to be valid";
    },
    consentIsValid: function() {
      return (
        this.$v.consent.$dirty === true && this.$v.consent.$invalid === false
      );
    },
  },
  validations: {
    firstName: {
      required,
      minLength: minLength(3),
    },
    lastName: {
      required,
      minLength: minLength(3),
    },
    email: {
      required,
      email,
    },
    message: {
      required,
      minLength: minLength(5),
    },
    consent: {
      mustBeTrue,
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/BlogBottonForm.scss";
</style>
