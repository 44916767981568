<template>
  <div class="azati-vwc-input-text">
    <input-validation-label
      :isVisible="isDirty && isInvalid"
      :message="errorMessage"
    ></input-validation-label>
    <input
      type="text"
      :placeholder="placeholder"
      :class="{ valid: isDirty && !isInvalid, invalid: isDirty && isInvalid }"
      v-model.lazy="value"
    />
    <div
      :class="{
        icon: true,
        valid: isDirty && !isInvalid,
        invalid: isDirty && isInvalid,
      }"
    ></div>
  </div>
</template>

<script>
import InputValidationLabel from "./InputValidationLabel";

export default {
  name: "InputText",
  props: {
    placeholder: {
      type: String,
      default: "Placeholder text:",
    },
    isDirty: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "Insert your error message",
    },
  },
  data() {
    return {
      value: "",
    };
  },
  watch: {
    value: function(value) {
      this.$emit("input", value);
    },
  },
  components: {
    "input-validation-label": InputValidationLabel,
  },
};
</script>

<style lang="scss">
$border-raduis: 6px;

.azati-vwc-input-text {
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;

  position: relative;

  input {
    border: 1.5px solid #909cad;
    background-color: #fff;
    border-radius: $border-raduis;
    padding: 15px 15px;
    font-family: "Poppins", "Verdana", sans-serif;
    color: #6e6e6e;
    outline: none;

    transition: color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &::placeholder {
      transition: color 0.3s ease;
    }

    &:focus,
    &:active,
    &.valid,
    &.invalid {
      color: #5aa3da;

      &::placeholder {
        color: #5aa3da;
      }
    }

    &:focus,
    &:active,
    &.valid {
      border-color: #5aa3da;
    }

    &.invalid {
      border-color: #f50000;
    }

    &.valid,
    &.invalid {
      padding-right: 40px;
    }

    &:focus,
    &:active {
      box-shadow: 0px 5px 10px 0px rgba(90, 162, 218, 0.1);
    }

    &:hover {
      cursor: pointer;
    }
  }

  .icon {
    background-position: center center;
    background-repeat: no-repeat;
    visibility: hidden;

    position: absolute;

    &.valid,
    &.invalid {
      visibility: visible;
    }

    &.valid {
      width: 17px;
      height: 17px;
      right: 15px;
      bottom: 16px;
      background-image: url(https://static.azati.ai/azati-vwc/resources/blog-bottom-form/check-mark.svg);
    }

    &.invalid {
      width: 20px;
      height: 20px;
      right: 15px;
      bottom: 15px;
      background-image: url(https://static.azati.ai/azati-vwc/resources/blog-bottom-form/error.svg);
    }
  }
}
</style>
