<template>
  <div class="azati-vwc-uploader-component">
    <label for="file-upload" class="uploader-label">
      {{ title }}
    </label>
    <input
      class="uploader"
      id="file-upload"
      type="file"
      accept=".jpg, .jpeg, .png, .pdf, .docx, .odt, .txt, .pptx"
      ref="file"
      multiple
      @change="handleFileUpload"
    />

    <div class="uploader-chosen-values-wrapper">
      <div class="chosen-value" v-for="item in files" :key="item.name">
        <span class="value-text">{{ item.name }}</span>
        <span
          class="delete-value-icon"
          @click="handleDeleteValue(item.name)"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UploaderComponent",
  props: {
    title: String,
  },
  data() {
    return {
      files: [],
    };
  },
  methods: {
    handleFileUpload() {
      this.files = [];
      this.files.push(...this.$refs.file.files);
    },
    handleDeleteValue(name) {
      this.files = this.files.filter((el) => el.name !== name);
    },
  },
  watch: {
    files: function(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/_vars.scss";

.azati-vwc-uploader-component {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  @media (max-width: 375px) {
    flex-direction: column-reverse;
  }

  .uploader-label {
    color: $color-azati-blue;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    margin-left: 30px;
    position: relative;
    cursor: pointer;
    margin-top: 5px;

    &:before {
      content: "";
      position: absolute;
      top: 0px;
      left: -30px;
      background-image: url("https://static.azati.ai/azati-vwc/resources/contact-us-form/clip-icon.svg");
      background-size: 100%;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
    }
  }

  .uploader {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .uploader-chosen-values-wrapper {
    .chosen-value {
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $color-grey;
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
      margin-bottom: 5px;

      display: flex;
      align-items: center;

      .delete-value-icon {
        background-image: url("https://static.azati.ai/azati-vwc/resources/contact-us-form/trash-icon.svg");
        background-size: 100%;
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
        cursor: pointer;
        margin-left: 8px;
      }
    }
  }
}
</style>
