const modifyURLParams = (key, value) => {
    // Update URL for conversion tracking
    const newURL = new URL(window.location.href);
    newURL.searchParams.set(key, value);
    const nextState = {
      additionalInformation: "Updated the URL with JS",
    };

    window.history.pushState(nextState, document.title, newURL);
}

export {
    modifyURLParams
}