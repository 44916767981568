<template>
  <div v-if="type === 'div'" class="azati-vwc-button">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Button",
  props: {
    href: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "div",
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/_vars.scss";

.azati-vwc-button {
  background-color: $color-azati-orange;
  border: 0 none;
  border-radius: 6px;
  color: $color-white;
  font-family: $font-family-bold;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  padding: 12px 20px;

  transition: all calc(#{$transition-duration} / 2.5)
    $transition-timing-function;

  box-sizing: border-box;

  &.azati-vwc-button--medium {
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding: 16px 20px;
  }

  &:hover,
  &:visited,
  &:focus {
    border-collapse: separate;
    box-shadow: 0 4px 10px 0 rgba(231, 114, 0, 0.51);
    outline: 0 none;
    cursor: pointer;
  }

  &::-moz-focus-inner {
    border: 0;
  }

  &:active {
    background-color: $color-dark-orange;
    border-collapse: separate;
    box-shadow: 0 4px 10px 0 rgba(231, 114, 0, 0.51);
    outline: 0 none;
  }

  @media (max-width: 380px) {
    min-height: 26px;
    width: 100%;
  }
  @media (min-width: 381px) {
    line-height: 1;
  }
}
</style>
