<template>
  <div id="azati-ai-cookie-banner">
    <div
      class="azati-vwc-cookie-banner azati-vwc-cookie-banner--intro"
      v-show="showBasicBanner"
    >
      <div class="azati-vwc-cookie-banner__window">
        <div class="azati-vwc-cookie-banner__intro-banner">
          <p class="title title_theme_bold title--blue">
            This website uses cookies
          </p>
          <p class="azati-vwc-cookie-banner__intro-banner__description">
            By clicking Accept, you consent to our use of cookies and tracking
            technologies. Review our
            <a href="/сookie-policy/">Cookie Policy</a> for more details or
            <span
              class="azati-vwc-cookie-banner__link"
              v-on:click="handlePreferencesClick"
              >manage your preferences</span
            >.
          </p>
        </div>
        <button-component
          @click.native="handleAcceptClick"
          class="azati-vwc-button--medium"
        >
          Accept cookies
        </button-component>
      </div>
    </div>

    <div
      class="azati-vwc-cookie-banner azati-vwc-cookie-banner--manage"
      v-show="showAdvancedBanner"
    >
      <div class="azati-vwc-cookie-banner__window">
        <div class="azati-vwc-cookie-banner__manage-banner">
          <div
            class="azati-vwc-cookie-banner__close"
            v-on:click="handleCloseClick"
          >
            <img
              src="https://static.azati.ai/azati-vwc/resources/cookie-banner/cookie-banner__close.jpg"
              alt=""
            />
          </div>

          <p class="title title_theme_bold title--light primary-headline">
            <span class="icon"></span>
            <span>Manage Cookie Preferences</span>
          </p>

          <p class="title title_theme_bold title--light secondary-headline">
            Strictly necessary cookies (Always Enabled)
          </p>
          <p class="text-content">
            These cookies are essential for you to browse the website and use
            its features, such as accessing secure areas of the site. These
            cookies will generally be first-party session cookies. While it is
            not required to obtain consent for these cookies, what they do and
            why they are necessary should be explained to the user.
          </p>
          <p class="text-content">
            Necessary cookies help make a website usable by enabling basic
            functions like page navigation and access to secure areas of the
            website. The website cannot function properly without these cookies.
          </p>

          <p class="title title_theme_bold title--light secondary-headline">
            <!-- Rounded switch -->
            <label class="toggle-switch">
              <input
                type="checkbox"
                v-on:click="handlePerformanceToggleClick"
                v-bind:checked="isPerformanceCookies"
              />
              <span class="slider"></span>
            </label>
            Performance Cookies
          </p>
          <p class="text-content">
            Performance cookies, which are sometimes called analytics cookies,
            collect information about your use of this Site and enable us to
            improve the way it works. For example, performance cookies show us
            which are the most frequently visited pages on the Site, allow us to
            see the overall patterns of usage on the Site, help us record any
            difficulties you have with the Site and show us whether our
            advertising is effective or not.
          </p>
          <p class="text-content">
            None of this information can be used to identify you. It is all
            aggregated and, therefore, anonymized.
          </p>

          <p class="title title_theme_bold title--light secondary-headline">
            <!-- Rounded switch -->
            <label class="toggle-switch">
              <input
                type="checkbox"
                v-on:click="handleMarketingToggleClick"
                v-bind:checked="isMarketingCookies"
              />
              <span class="slider"></span>
            </label>
            Marketing Cookies
          </p>
          <p class="text-content">
            Marketing cookies are used to track visitors across websites. The
            intention is to display ads that are relevant and engaging for the
            individual user and thereby more valuable for publishers and third
            party advertisers.
          </p>
          <p class="text-content">
            Some common applications of cookies are to select advertising based
            on what is relevant to a user; to improve reporting on campaign
            performance; and to avoid showing ads the user has already seen.
          </p>

          <p class="title title_theme_bold title--light secondary-headline">
            <!-- Rounded switch -->
            <label class="toggle-switch">
              <input
                type="checkbox"
                v-on:click="handleAllToggleClick"
                v-bind:checked="isMarketingCookies & isPerformanceCookies"
              />
              <span class="slider"></span>
            </label>
            Select All
          </p>
          <div class="button-wrapper">
            <button-component
              @click.native="handleSubmitClick"
              class="azati-vwc-button--medium"
            >
              Save preferences
            </button-component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "./utility/forms/Button";

export default {
  name: "CookieBanner",
  data() {
    return {
      showBasicBanner: false,
      showAdvancedBanner: false,
      isPerformanceCookies: true,
      isMarketingCookies: true,
    };
  },
  methods: {
    handleAcceptClick: function() {
      this.showBasicBanner = false;
      this.setCookie(
        "azati-cookie-preferences",
        JSON.stringify({
          marketing: true,
          performance: true,
        }),
        90
      );
      document.location.reload();
    },
    handlePreferencesClick: function() {
      this.showAdvancedBanner = true;
      this.showBasicBanner = false;
    },
    handleCloseClick: function() {
      this.showAdvancedBanner = false;
    },
    handlePerformanceToggleClick: function() {
      this.isPerformanceCookies = !this.isPerformanceCookies;
      this.isSomeSelected = true;
    },
    handleMarketingToggleClick: function() {
      this.isMarketingCookies = !this.isMarketingCookies;
      this.isSomeSelected = true;
    },
    handleAllToggleClick: function() {
      if (this.isPerformanceCookies && this.isMarketingCookies) {
        this.isPerformanceCookies = false;
        this.isMarketingCookies = false;
      } else {
        this.isPerformanceCookies = true;
        this.isMarketingCookies = true;
      }
    },
    handleSubmitClick: function() {
      this.showAdvancedBanner = false;

      this.setCookie(
        "azati-cookie-preferences",
        JSON.stringify({
          marketing: this.isMarketingCookies,
          performance: this.isPerformanceCookies,
        }),
        90
      );

      document.location.reload();
    },
    setCookie: function(cname, cvalue, exdays) {
      // https://www.w3schools.com/js/js_cookies.asp

      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      const expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    getCookie: function(cname) {
      // https://www.w3schools.com/js/js_cookies.asp

      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");

      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }

      return "";
    },
    checkCookie: function(cname) {
      // https://www.w3schools.com/js/js_cookies.asp

      let cookie = this.getCookie(cname);
      if (cookie != "") {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    if (this.checkCookie("azati-cookie-preferences")) {
      // Checks if the cookie is present, after that fills in the state
      const cookie = JSON.parse(this.getCookie("azati-cookie-preferences"));

      if ("marketing" in cookie && "performance" in cookie) {
        this.isMarketingCookies = Boolean(cookie.marketing);
        this.isPerformanceCookies = Boolean(cookie.performance);
      }
    } else {
      // If cookie is not found, it sets the default value to false shows the banner
      this.setCookie(
        "azati-cookie-preferences",
        JSON.stringify({
          marketing: false,
          performance: false,
        }),
        90
      );

      this.showBasicBanner = true;
    }
  },
  components: {
    "button-component": Button,
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/CookieBanner.scss";
</style>
