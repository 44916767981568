<template>
  <div
    class="azati-vwc-short-recruitment-form"
    id="azati-vwc-short-recruitment-form"
  >
    <p>
      To submit your request, please
    </p>
    <p class="a-like" @click="handleLinkClick">
      fill in the recruitment form
    <p>- or -</p>
    <p>attach your resume and cover letter below</p>
    <div class="buttons-wrapper">
      <button-uploader
        title="Attach CV"
        id="cv"
        v-model="cvs"
      ></button-uploader>
      <button-uploader
        title="Attach Cover Letter"
        id="cl"
        v-model="coverLetters"
      ></button-uploader>
      <button-component
        @click.native="handleButtonClick"
        class="azati-vwc-button--medium"
      >
        <span>{{ buttonMessage }}</span>
      </button-component>
    </div>
  </div>
</template>

<script>
import { toBase64 } from "../utils/base64";
import { modifyURLParams } from "../utils/modifyURLParams"; 

import Button from "./utility/forms/Button";
import ButtonUploader from "./utility/forms/ButtonUploader";

export default {
  name: "ShortRecruitmentForm",
  data() {
    return {
      buttonMessage: "Send application",
      processing: false,
      cvs: [],
      coverLetters: [],
      hrFormLink: "https://hr.itibo.com"
    };
  },
  components: {
    "button-component": Button,
    "button-uploader": ButtonUploader,
  },
  methods: {
    async generateFiles(files) {
      let base64files = [];

      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        const fileBase = await toBase64(file);

        base64files.push({
          name: file.name,
          type: file.type,
          base64: fileBase,
        });
      }

      return base64files;
    },
    handleLinkClick(){
      modifyURLParams("conversion-tracking", "leaving-to-hr-form");

      // Setting small timeout to allow Facebook pixel track the URL change
      setTimeout(() => {
        window.open(this.hrFormLink, '_blank').focus();
      }, 500);
    },
    async handleButtonClick() {
      if (this.cvs.length != 0) {
        this.processing = true;
        this.buttonMessage = "Processing";

        fetch("/FormAPI/short-recruitment-form/", {
          // Fetch docs are here: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Authorization: "fjF*3ifj3nap1!04f82dsj!!#%MqpzZQM",
          },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: JSON.stringify({
            cvs: await this.generateFiles(this.cvs),
            cover_letters: await this.generateFiles(this.coverLetters),
          }),
        }).then((response) => {
          this.processing = false;
          if (response.status === 200) {
            this.buttonMessage = "Success!";

            // Update URL for conversion tracking
            modifyURLParams("conversion-tracking", "success");
          } else {
            this.buttonMessage = "Error!";
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/_vars.scss";

.azati-vwc-short-recruitment-form {
  p,
  a {
    font-family: $font-family-base;
    text-align: center;
    margin: 0 auto;
    line-height: 30px;
    color: $color-grey;
    font-size: 18px;
    font-weight: 300;
  }

  .a-like {
    text-decoration: none;
    color: $color-black-blue;
    transition: color 0.3s ease;
    cursor: pointer;

    &:hover {
      color: darken($color: $color-black-blue, $amount: 10);
    }
  }

  .buttons-wrapper {
    margin-top: 15px;

    // display: flex;
    // align-items: center;
    // justify-content: center;
    // flex-direction: column;

    .azati-vwc-button--medium {
      max-width: 320px;
      margin: 0 auto;
    }
  }
}
</style>
