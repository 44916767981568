<template>
  <div class="azati-vwc-button-uploader-component">
    <label :for="id" class="uploader-label azati-vwc-button--medium">
      {{ title }}
    </label>

    <input
      class="uploader"
      :id="id"
      type="file"
      accept=".jpg, .jpeg, .png, .pdf, .docx, .odt, .txt, .pptx"
      :ref="id"
      multiple
      @change="handleFileUpload"
    />

    <div class="uploader-chosen-values-wrapper">
      <div class="chosen-value" v-for="item in files" :key="item.name">
        <span class="value-text">{{ item.name }}</span>
        <span
          class="delete-value-icon"
          @click="handleDeleteValue(item.name)"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UploaderComponent",
  props: {
    title: String,
    id: String,
  },
  data() {
    return {
      files: [],
    };
  },
  methods: {
    handleFileUpload() {
      this.files = [];
      this.files.push(...this.$refs[this.id].files);
    },
    handleDeleteValue(name) {
      this.files = this.files.filter((el) => el.name !== name);
    },
  },
  watch: {
    files: function(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/_vars.scss";

.azati-vwc-button-uploader-component {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-bottom: 15px;

  .uploader-label {
    background-color: $color-primary-blue;
    border: 0 none;
    border-radius: 6px;
    color: $color-white;
    font-family: $font-family-bold;
    font-weight: normal;
    text-align: center;
    text-transform: uppercase;
    padding: 12px 20px;
    width: 100%;

    transition: all calc(#{$transition-duration} / 2.5)
      $transition-timing-function;

    box-sizing: border-box;

    &.azati-vwc-button--medium {
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 1.5px;
      padding: 16px 20px;
    }

    &:hover,
    &:visited,
    &:focus {
      border-collapse: separate;
      box-shadow: 0 4px 10px 0 rgba(55, 108, 255, 0.51);
      outline: 0 none;
      cursor: pointer;
    }

    &::-moz-focus-inner {
      border: 0;
    }

    &:active {
      background-color: $color-black-blue;
      border-collapse: separate;
      box-shadow: 0 4px 10px 0 rgba(55, 108, 255, 0.51);
      outline: 0 none;
    }

    @media (max-width: 380px) {
      min-height: 26px;
      width: 100%;
    }
    @media (min-width: 381px) {
      line-height: 1;
    }
  }

  .uploader {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .uploader-chosen-values-wrapper {
    margin-top: 10px;

    .chosen-value {
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $color-grey;
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
      margin-bottom: 5px;

      display: flex;
      align-items: center;

      .delete-value-icon {
        background-image: url("https://static.azati.ai/azati-vwc/resources/contact-us-form/trash-icon.svg");
        background-size: 100%;
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
        cursor: pointer;
        margin-left: 8px;
      }
    }
  }
  flex-direction: column;
}
</style>
