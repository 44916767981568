const clients = [
  {
    clientName: "Aptagen",
    filename: "aptagen.png",
  },
  { clientName: "Auction.com", filename: "auction-com.png" },
  { clientName: "Chilewich", filename: "chilewich.png" },
  { clientName: "CIB", filename: "cib.png" },
  { clientName: "Citizen", filename: "citizen.png" },
  { clientName: "Clarivate", filename: "clarivate.png" },
  { clientName: "CMSC", filename: "cmsc.png" },
  { clientName: "CROC", filename: "croc.png" },
  { clientName: "Digatex", filename: "digatex.png" },
  { clientName: "DOXA Insurance", filename: "doxa-insurance.png" },
  { clientName: "Empyreal Strings", filename: "empyreal-strings.png" },
  { clientName: "Fliit", filename: "fliit.png" },
  { clientName: "Geolytics", filename: "geolytics.png" },
  { clientName: "HOST", filename: "host.png" },
  { clientName: "IOMSN", filename: "iomsn.png" },
  { clientName: "Mangrove", filename: "mangrove.png" },
  { clientName: "Marketboomer", filename: "marketboomer.png" },
  { clientName: "MedPro Group", filename: "medpro-group.png" },
  { clientName: "Roscosmos", filename: "roscosmos.png" },
  { clientName: "Career Star Group", filename: "stcareer-star-group.png" },
  { clientName: "Teambook", filename: "teambook.png" },
  { clientName: "TopHotelProjects", filename: "tophotelprojects.png" },
  { clientName: "Venstar Exchange", filename: "venstar-exchange.png" },
];

const getClientList = () => {
  return clients;
};

const getClientByName = (name) => {
  let client = clients.filter((el) => el.clientName === name)[0];
  return {
    clientName: client.clientName,
    logoLink:
      "https://static.azati.ai/azati-vwc/resources/our-clients/" +
      client.filename,
  };
};

export { getClientList, getClientByName };
