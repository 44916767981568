<template>
  <div class="azati-vwc-input-validation-message" v-visible="isVisible">
    <span class="error-message">{{ message }}</span>
  </div>
</template>

<script>
export default {
  name: "InputValidationLabel",
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "This is default validation message",
    },
  },
};
</script>

<style lang="scss">
.azati-vwc-input-validation-message {
  .error-message {
    font-family: "Poppins", "Verdana", sans-serif;
    font-size: 12px;
    color: #f50000;
    font-weight: 600;
  }
}
</style>
