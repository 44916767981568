<template>
  <div
    :class="[
      'row',
      elements.length === 4 ? 'four-items' : '',
      elements.length === 6 ? 'six-items' : '',
    ]"
  >
    <div
      class="col"
      v-for="(element, index) in elements"
      :key="element.clientName"
    >
      <div class="img-wrapper">
        <transition
          name="show-logo"
          @after-leave="
            if (index === elements.length - 1) {
              onAnimationEnd();
            }
          "
        >
          <img
            :src="element.logoLink"
            :alt="element.clientName"
            v-show="elementShowStates[index]"
            @mouseover="onLogoMouseOver"
            @mouseout="onLogoMouseOut"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurClientsRow",
  props: {
    elements: {
      type: Array,
      defaul: [],
    },
  },
  data() {
    return {
      elementShowStates: [],
      animationDuration: 5000,
      delayBetweenElements: 200,
      defaultTimerValue: 100,
      startingAnimationTimers: [],
      endingAnimationTimers: [],
    };
  },
  computed: {
    timers: function() {
      let timers = [];

      switch (this.elements.length) {
        case 4:
          for (let index = 0; index < 4; index++) {
            timers.push(
              this.defaultTimerValue + (index + 1) * this.delayBetweenElements
            );
          }
          break;
        case 6:
          for (let index = 0; index < 6; index++) {
            timers.push(
              this.defaultTimerValue + (index + 1) * this.delayBetweenElements
            );
          }
          break;
        default:
          break;
      }

      return timers;
    },
  },
  methods: {
    onAnimationEnd() {
      this.$emit("animation-finished");
    },
    onLogoMouseOver() {
      this.destroyTimers();
    },
    onLogoMouseOut() {
      this.runStartingAnimationTimers();
      this.runEndingAnimationTimers();
    },
    runStartingAnimationTimers() {
      this.startingAnimationTimers = [];
      for (let index = 0; index < this.timers.length; index++) {
        const animationTimer = setTimeout(() => {
          this.$set(this.elementShowStates, index, true);
        }, this.timers[index]);
        this.startingAnimationTimers.push(animationTimer);
      }
    },
    runEndingAnimationTimers() {
      this.endingAnimationTimers = [];
      for (let index = 0; index < this.timers.length; index++) {
        const timer = this.timers[index];
        // Ending animation
        const animationTimer = setTimeout(() => {
          this.$set(this.elementShowStates, index, false);
        }, timer + this.animationDuration);
        this.endingAnimationTimers.push(animationTimer);
      }
    },
    generateElementShowStates() {
      switch (this.elements.length) {
        case 4:
          this.elementShowStates = [false, false, false, false];
          break;
        case 6:
          this.elementShowStates = [false, false, false, false, false, false];
          break;

        default:
          return [];
      }
    },
    destroyTimers() {
      this.startingAnimationTimers.forEach((timer) => {
        clearTimeout(timer);
      });
      this.startingAnimationTimers = [];
      this.endingAnimationTimers.forEach((timer) => {
        clearTimeout(timer);
      });
      this.endingAnimationTimers = [];
    },
  },
  mounted() {
    this.generateElementShowStates();
    this.runStartingAnimationTimers();
    this.runEndingAnimationTimers();
  },
  destroyed() {
    this.destroyTimers();
  },
};
</script>

<style>
.show-logo-enter {
  transition: all 0.5s ease;
  margin-bottom: -25px;
  opacity: 0;
}

.show-logo-enter-to {
  transition: all 0.5s ease;
  margin-bottom: 0px;
  opacity: 1;
}

.show-logo-leave {
  transition: all 0.5s ease;
  margin-top: 0px;
  opacity: 1;
}

.show-logo-leave-to {
  transition: all 0.5s ease;
  margin-top: -25px;
  opacity: 0;
}
</style>
