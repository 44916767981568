<template>
  <div class="azati-vwc-form-alert">
    <p class="message-text" v-html="message"></p>
  </div>
</template>

<script>
export default {
  name: "FormAlert",
  props: {
    message: {
      type: String,
      default: "This is default alert message",
    },
  },
};
</script>

<style lang="scss">
.azati-vwc-form-alert {
  background-color: #fff5f5;
  padding: 14px 20px 14px 15px;
  border-left: 5px solid red;
  margin-right: 15px;

  p.message-text {
    font-family: "Poppins", "Verdana", sans-serif;
    font-size: 14px;
    color: #6e6e6e;
    margin-left: 7px;
    margin: 0;

    &:hover {
      cursor: pointer;
    }

    a {
      text-decoration: none;
    }
  }
}
</style>

<style lang="scss">
.azati-vwc-form-alert {
  box-sizing: border-box;
  p.message-text {
    a {
      text-decoration: none;
      color: #ff9c42;

      transition: color 0.3s ease;
      text-decoration: underline;

      &:hover,
      &:focus,
      &:visited {
        color: #f9823a;
      }
    }
  }
}
</style>
